<template>
  <div class="container h-full px-2 py-8 mx-auto">
    <slot></slot>
  </div>
</template>

<script>
  export default {};
</script>

<style scoped></style>
